.flex-1 {
  flex: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.opacity-0 {
  opacity: 0 !important;
}

.simple-spin {
  animation: simple-spin infinite 0.5s linear;
}

@keyframes simple-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
